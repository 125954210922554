import React, { useContext } from "react";

import { FormUpdateStockCal } from "../../components/FormUpdateStockCal";
import { SectionTitle } from "../../components/SectionTitle";

import { NavbarContext } from "../../context/NavbarContext";

export const UpdateCal = () => {
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white md:flex">
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}>
      </div>

      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <SectionTitle titleTop={"Actualizar silos"} titleBottom={"Estado de silos y operaciones"} />
        <FormUpdateStockCal />
      </div>
    </section>
  )
}