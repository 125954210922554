import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
 
// services
import { getAllRoleService } from "../services/auth/role.service";
import { createUserService } from "../services/auth/access.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";
import { Select } from "./Select";

// utils
import * as ERRORS from "../constants/errors";

export const FormRegisterUser = () => {  
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [ roles, setRoles ] = useState(null);
  const [ params, setParams ] = useState( { loading: false, advice: null } );
  
  useEffect( () => {
    const fechtData = async () => {
      const data = await getAllRoleService();
      setRoles(data);
    }
    fechtData();
  }, [ setRoles ]);
    
  const onSubmit = async (formData) => {
    setParams({ ...params, loading: true })

    const body = JSON.parse(JSON.stringify(formData));
    const { status, message } = await createUserService(body);
    
    if (status) reset();

    setParams({ loading: false, advice: message });
  }

  return (              
    <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper">
      <Select
        id="roleId"
        label="Rol" placeholder="Seleccionar rol"
        options={ roles }
        register={ register }
        constraints={{ 
          required: ERRORS.REQUIRED
        }} 
        errors={ errors.roleId }
      />
              
      <Input 
        id="name"
        type="text" 
        label="Nombre(s)" placeholder="Ingresar nombre(s) personal"  
        register={ register }
        constraints={{ 
          minLength: { 
            value: 3, 
            message: ERRORS.MIN_3
          }, 
          pattern: {
            value: /^[a-zA-Z ]*$/,
            message: ERRORS.ONLY_LETTERS
          },
          required: ERRORS.REQUIRED
        }}
        errors={ errors.name }
      />
        
      <Input
        id="surname" 
        type="text" 
        label="Apellido(s)" placeholder="Ingresar apellido(s)"
        register={ register }
        constraints={{ 
          minLength: { 
            value: 3, 
            message: ERRORS.MIN_3
          }, 
          pattern: {
            value: /^[a-zA-Z ]*$/,
            message: ERRORS.ONLY_LETTERS
          },
          required: ERRORS.REQUIRED
        }} 
        errors={ errors.surname } 
      />
        
      <Input 
        id="username"
        type="text"
        label="Usuario" placeholder="Asignar un ID de ingreso" 
        register={ register }
        constraints={{ 
          minLength: { 
            value: 8, 
            message: ERRORS.MIN_8
          },
          pattern: {
            value: /^[a-z0-9]*$/,
            message: ERRORS.ONLY_LOWERCASE
          },
          required: ERRORS.REQUIRED
        }}
        errors={ errors.username }
      />

      { params.loading 
        ? <LoadingForm /> 
        : <button 
            type="submit"
            className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
          > 
            Enviar 
          </button> 
      }
      { params.advice 
          && <span className="form__message">* { params.advice } *</span> 
      }
    </form>    
  )
}