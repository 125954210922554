export const createSiloService = async (data) => {
  const URL = `/silo/create`;
  const requestSetup = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(URL, requestSetup);
    const jsonData = await response.json();
    return jsonData;
  }
  catch (err) {    
    return ({
      success: false, 
      token: null,
      message: "Error en la conexión de red"
    });
  }
}

export const updateSiloService = async ( id, data ) => {
  const URL = `/silo/update/${id}`;
  const requestSetup = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  
  try {
    const response = await fetch(URL, requestSetup);
    const jsonData = await response.json();
    return jsonData;
  }
  catch (err) {    
    return ({
      success: false, 
      message: "Error en la conexión de red"
    });
  }
}

export const removeSiloService = async ( id ) => {
  const URL = `/silo/remove/${id}`;
  const requestSetup = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = await fetch(URL, requestSetup);
    const jsonData = await response.json();
    return jsonData;
  }
  catch (err) {    
    return ({
      success: false, 
      message: "Error en la conexión de red"
    });
  }
}