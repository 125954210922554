import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// context
import { SesionContext } from "../context/SesionContext";

// services
import { loginService } from "../services/auth/access.service";

// components
import { LoadingForm } from "./LoadingForm";

// utils
import { FaUserAlt, FaLock } from "react-icons/fa";
import * as ERRORS from "../constants/errors";

export const FormLogin = () => {  
  const { setUser, setLog } = useContext(SesionContext);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [ params, setParams ] = useState( { loading: false, advice: null } );  
  
  const navigate = useNavigate();

  const onSubmit = async (formData) => {    
    setParams({ advice: null, loading: true });

    const { username, password } = JSON.parse(JSON.stringify(formData));

    if (!username || !password) return setParams({ loading: false, advice: "Por favor ingrese todos los campos" });          

    const response = await loginService({ username, password });

    if (response.status) {
      const { name, surname, role, isLoggedIn } = response;      
      setLog(isLoggedIn);
      setUser({ name, surname, role });
      reset();
      navigate("/main");
    }
    
    setParams({ loading: false, advice: response.message });
  }

  return (
    <div className="bg-gray-200 sm:w-96 rounded-2xl border border-white shadow-lg shadow-black/50 p-7 sm:p-10">
      <form className="flex flex-col gap-4 font-inter" onSubmit={ handleSubmit(onSubmit) }>
        <div className="flex flex-col">
          <label forhtml="username" className="login-label"> Usuario: </label>
          <div className="relative">
            <div className="absolute top-0 bottom-0 my-auto inset-y-0 flex items-center pl-3"> <FaUserAlt className="text-accent"/> </div>
            <input 
              id="username" 
              type="text" 
              className="login-input" placeholder="Ingresar usuario"
              {...register("username", 
                { 
                  pattern: {
                    value: /^[a-z0-9]*$/,
                    message: "Solo minúsculas y números"
                  },
                  required: ERRORS.REQUIRED
                }
              )}
            />
          </div>
          { errors.username && <p className="font-medium text-red-400 text-xs tracking-tight p-1 "> { errors.username.message } </p> }
        </div>
        <div className="flex flex-col mt-5">
          <label forhtml="password" className="login-label"> Contraseña: </label>
          <div className="relative">
            <div className="absolute inset-y-0 flex items-center pl-3"> <FaLock className="text-accent"/> </div>
            <input 
              id="password" 
              type="password" 
              className="login-input" placeholder="Ingresar contraseña" 
              { ...register("password",
                { 
                  required: ERRORS.REQUIRED
                }
              )}
            />
          </div>
          { errors.password && <p className="font-medium text-red-400 text-xs tracking-tight p-1"> { errors.password.message } </p> }
        </div>
        { params.loading ? <LoadingForm /> : <button type="submit" className="login-btn"> Ingresar </button> }
        { params.advice && <div className="text-center text-red-400 font-inter text-sm font-medium t-5"> * { params.advice } * </div> }
      </form>
    </div>
  )
}