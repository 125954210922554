import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Services
import { updatePasswordService } from "../services/auth/access.service";

// Components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";

// utils
import * as ERRORS from "../constants/errors";

export const FormUpdatePassword = () => {    
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [ params, setParams ] = useState( { loading: false, advice: null } )
  const [ showPassword, setShowPassword ] = useState(false);

  const onSubmit = async (formData) => {    
    setParams({ ...params, loading: true });

    const body = JSON.parse(JSON.stringify(formData));    
    const password1 = body["password"];
    const password2 = body["repeatPassword"];

    if ( password1 === password2 ) {
      const data = { 
        oldPassword: body["oldPassword"],
        newPassword: password1,
      }
      const { message } = await updatePasswordService(data);      
      setParams({ loading: false, advice: message });
      reset();
    }
    else {
      return setParams({ loading: false, advice: "Nueva contraseña no coincide",});
    }  
  }

  return (      
    <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper lg:w-[50%]">
      <Input 
        id="oldPassword"
        type={ showPassword ? "text" : "password" }  
        label="Contraseña actual" placeholder="Ingresar contraseña actual"  
        register={ register }
        constraints={{ 
          required: ERRORS.REQUIRED
        }}
        errors={ errors.oldPassword }
      />

      <Input 
        id="password" 
        type={ showPassword ? "text" : "password" }        
        label="Nueva contraseña" placeholder="Ingresar nueva contraseña" 
        register={ register }
        constraints={{ 
          minLength: { 
            value: 8, 
            message: ERRORS.MIN_5
          },
          required: ERRORS.REQUIRED
        }}
        errors={ errors.password }
      />

      <Input 
        id="repeatPassword" 
        type={ showPassword ? "text" : "password" }        
        label="Repetir contraseña" placeholder="Repetir nueva contraseña"
        register={ register }
        constraints={{ 
          minLength: { 
            value: 8, 
            message: ERRORS.MIN_8
          },
          required: ERRORS.REQUIRED
        }}
        errors={ errors.repeatPassword }
      />

      <label className="flex gap-2 pl-3 md:w-4/5 self-end font-inter font-medium text-sm text-gray-500 tracking-tight">
        <input 
          type="checkbox" 
          id="showPassword" 
          onChange={event => setShowPassword( event.currentTarget.checked ) } 
        /> 
        <span>Mostrar contraseña</span> 
      </label>

      { params.loading 
          ? <LoadingForm /> 
          : <button 
              type="submit" 
              className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
            >
              Enviar 
            </button> 
      }
      { params.advice 
        && <span className="form__message">* { params.advice } *</span>
      }  
    </form>
  )
}