import { useState } from "react";

import { SesionContext } from "./SesionContext";

export const SesionProvider = ({ children }) => {
  const [ log, setLog ] = useState(false);
  const [ user, setUser ] = useState({name: null, surname: null, role: null});
  
  const resetValues = () => {    
    setLog(false);
    setUser({ name: null, surname: null, role: null });    
  }

  const data = { 
    log,
    setLog,
    user,
    setUser,
    resetValues
  }
    
  return (
    <SesionContext.Provider value={ data }>
      { children }    
    </SesionContext.Provider>
  )
}