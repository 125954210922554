export const getAllOperationService = async () => {
  const URL = `/operation`
  const query = await fetch(URL)  
  const jsonData = await query.json()
  return jsonData
}

export const getCountSiloService = async () => {
  const URL = `/operation/silos`
  const query = await fetch(URL)  
  const jsonData = await query.json()
  return jsonData
}

export const createOperationService = async (data) => {
  const URL = `/operation/create`
  const query = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })  
  const jsonData = await query.json()
  return jsonData
}