import React from "react"

import { FormLogin } from "../../components/FormLogin"
import { Logo } from "../../components/Logo"

export const Login = () => {
  return (
    <div className="
      bg-gradient-to-b from-accent to-[#4e3274]
      fixed top-0 bottom-0 left-0 right-0 
      overflow-auto 
      flex flex-col items-center justify-center
      h-ful min-h-full 
      py-10"
    > 
      <Logo />
      <FormLogin />
    </div>
  )
}