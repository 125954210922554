import React from "react";
import { Link } from "react-router-dom";

export const NavLink = ({ currentPath, itemPath, text, icon }) => {
  return (
    <Link 
      to= { itemPath } 
      className={`nav__link nav__link--font nav__link--hover  ${ currentPath  === itemPath && "bg-white/10 border border-gray-400/20 rounded-lg text-white hover:bg-white/10 "} `}
    >
      { icon } { text }
    </Link> 
  )
}