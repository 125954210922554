import React from "react";

export const Select = ({ register, constraints, errors, id, options, label, placeholder }) => {
  return (
    <div className="field__wrapper">
      <label htmlFor={ id } className="field__label field__label--font"> { label }: </label>
      <div className="field__input">
        <select 
          id={ id } 
          className="input__box input__box--font input__box--focus"
          { ...register(id, constraints) } 
        >
          <option value="" defaultValue> { placeholder } </option>
          { 
            options && options.map((element, key) => 
              <option key={ key } value={ element.id } > { element.name } </option> 
          )}
        </select>
        { errors && <p className="field__error"> { errors.message } </p> }  
      </div>
    </div>
  )
}