import "./fonts/InterFont/Inter-Bold.woff";
import "./fonts/InterFont/Inter-Bold.woff2";
import "./fonts/InterFont/Inter-ExtraBold.woff";
import "./fonts/InterFont/Inter-ExtraBold.woff2";
import "./fonts/InterFont/Inter-Light.woff";
import "./fonts/InterFont/Inter-Light.woff2";
import "./fonts/InterFont/Inter-Medium.woff";
import "./fonts/InterFont/Inter-Medium.woff2";
import "./fonts/InterFont/Inter-Regular.woff";
import "./fonts/InterFont/Inter-Regular.woff2";
import "./fonts/InterFont/Inter-SemiBold.woff";
import "./fonts/InterFont/Inter-SemiBold.woff2";

import { AppRouter } from "./routers/AppRoute";
import { SesionProvider } from "./context/SesionProvider";
import { NavbarProvider } from "./context/NavbarProvider";

import "./index.css"

function App() {
  return (
    <NavbarProvider>
      <SesionProvider>
        <AppRouter />
      </SesionProvider>
    </NavbarProvider>
  );
}

export default App;