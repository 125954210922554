import React, { useEffect, useState } from "react";

// components
import { FormCreateOwnerLocation } from "./FormCreateOwnerLocation";

// services
import { getAllOwnerLocationService } from "../services/cal/ownerLocation.service";

export const MenuOperation = () => {
  const [ data, setData ] = useState(null);
  const [ tab, setTab ] = useState("new");
  
  useEffect( () => {
    const fechtData = async () => {
      const response = await getAllOwnerLocationService();
      setData(response);
    }    
    fechtData();
  }, [ setData ] );

  const updateData = (data) => setData(data);
  
  return (  
    <main className="flex flex-col-reverse gap-3 lg:flex-row-reverse justify-between">
      <div className="section__wrapper lg:w-[45%]">
        <h4 className="section__header section__header--font "> Operaciones </h4>
        <ul className="font-inter text-sm text-gray-700 tracking-tight">
        { data && data.map( (item, index) => 
            <li key={ index } className="flex justify-between even:bg-gray-200/80 py-1.5 px-3">
              <span className="font-semibold"> { item.ownerName } </span>
              <span className="font-mnedium"> { item.locationName } </span>
            </li>
        )}
        </ul>
      </div>
      <div className="section__wrapper lg:w-[45%]">
        <h4 className="section__header section__header--font flex gap-8"> 
          <span className="section__tab" onClick={ () => setTab("new") } > Nuevo </span> 
        </h4>
        { tab === "new" && <FormCreateOwnerLocation callback={ updateData }/> }
      </div>
    </main>
  )
}