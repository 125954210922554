import React, { useState, useEffect } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

export const StatusBar = ({ partial, total }) => {      
  const [ colorState, setColorState] = useState("#FFF");
  const [ percentageState, setPercentageState ] = useState(100);

  useEffect( () => {
    const dem = Number(total);
    const num = Number(partial);  
      
    //setPercentageState((Number(((num/dem) * 100).toFixed())));   
    
    const tmp = (Number(((num/dem) * 100).toFixed()));
    setPercentageState(tmp);
    if (80<=tmp ) {
      setColorState("#74B81F");
    }
    else if (25<tmp && tmp<80) {
      setColorState("#FFCC00");
    }
    else if (tmp<=25) {
      setColorState("#ED0000");
    }   
  },[ setPercentageState, setColorState, partial, total ]);

  return (
    <ProgressBar 
      completed={ percentageState }  
      bgColor={ colorState } 
      labelAlignment = { "left"} 
      height = {"24px"}      
      labelColor = {"#EFEFEF"}
      labelSize = {"10px"}    
      className="wrapper"
      labelClassName="label"    
    />
  );
}