import React from "react";

export const NavUsername = ({ name, surname, role }) => {
  return (    
    <div className="flex flex-col text-right md:text-left text-xs md:text-sm font-inter text-gray-200">
      <h3 className="font-medium">
        <span> { name } </span>   
        <span> { surname } </span>
      </h3>
      <span className="font-bold">
        { role }
      </span>
    </div> 
  )
}
