import React, { useContext } from "react";

import { SesionContext } from "../../context/SesionContext";
import { NavbarContext } from "../../context/NavbarContext";

export const Forbidden = () => {
  const { user } = useContext(SesionContext);
  const { sideMinimize } = useContext(NavbarContext);

  return (        
  <section className="bg-white flex">
    <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}>
    </div>

    <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
      <div className="section__wrapper font-inter">
        <h3 className="font-bold text-5xl md:text-6xl text-accent tracking-tight"> 
          Acceso denegado. 
        </h3>
        <p className="font-medium text-sm text-gray-700 tracking-tight"> 
          Estimado { user.name } {user.surname}, no tiene permisos para acceder a esta sección.
        </p>
      </div>
    </div>
  </section>
  )
}