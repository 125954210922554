import React, { useContext } from "react";
import { MenuSilo } from "../../components/MenuSilo";
import { SectionTitle } from "../../components/SectionTitle";

import { NavbarContext } from "../../context/NavbarContext";

export const Silo = () => {
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white md:flex">
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}> 
      </div>
      
      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <SectionTitle titleTop={"Editar silos"} titleBottom={"Estado de silos y operaciones "} />
        <MenuSilo />
      </div>
    </section>
  )
}