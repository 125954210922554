import * as PATH from "./routes";
import { MdOutlineBookmarkAdd, MdOutlineBookmarkAdded, MdMoreTime } from "react-icons/md";
import { BiUserPlus, BiUserCheck } from "react-icons/bi";
import { BsMinecartLoaded, BsKey, BsSignpost2, BsBarChart,  } from "react-icons/bs";
import { TbUser } from "react-icons/tb";

export const items = [
  { 
    name: "Registrar usuario", 
    path: PATH.REGISTER_USER,     
    icon: <BiUserPlus className="text-base" />
  },
  { 
    name: "Editar usuario", 
    path: PATH.UPDATE_USER,  
    icon: <BiUserCheck className="text-base" />
  },
  { 
    name: "Cambiar contraseña",
    path: PATH.UPDATE_PASSWORD,  
    icon: <BsKey className="text-base" />
  },
]

export const calItems = [
  { 
    name: "Actualizar stock", 
    path: PATH.UPDATE_STOCK_CAL, 
    icon: <MdMoreTime className="text-base"/>
  },
  { 
    name: "Ver status", 
    path: PATH.STATUS_STOCK_CAL,     
    icon: <BsBarChart  className="text-base"/>
  },
  { 
    name: "Cliente", 
    path: PATH.OWNER,
    icon: <TbUser  className="text-base"/>
  },
  { 
    name: "Ubicación", 
    path: PATH.LOCATION,  
    icon: <BsSignpost2  className="text-base"/>
  },
  { 
    name: "Material", 
    path: PATH.MATERIAL, 
    icon: <BsMinecartLoaded  className="text-base"/>
  },
  { 
    name: "Operaciones", 
    path: PATH.OPERATION,     
    icon: <MdOutlineBookmarkAdd  className="text-base"/>
  },
  { 
    name: "Silos", 
    path: PATH.SILO, 
    icon: <MdOutlineBookmarkAdded  className="text-base"/>
  },
  
]