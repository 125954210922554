import React, { useContext } from "react";

import { SectionTitle } from "../../components/SectionTitle";
import { TableUpdateUser } from "../../components/TableUpdateUser";

import { NavbarContext } from "../../context/NavbarContext";

export const User = () => {
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white flex">
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}> 
      </div>

      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <SectionTitle titleTop={"Editar usuario"} titleBottom={"Administración de usuario "} />
        <TableUpdateUser />
      </div>
    </section>
  )
}