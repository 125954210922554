import React, { useEffect, useState, useCallback  } from "react";
import DataTable from "react-data-table-component";

// components
import { StatusBar } from "./StatusBar";
import { DownloadReport } from "./DownloadReport";
import { SectionTitle } from "./SectionTitle";

// services
import { getUpdateService } from "../services/cal/update.service";

// styles
import { customStyles } from "../constants/tableProperties";

export const TableUpdateStock = () => {
  const [ data, setData ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  useEffect( () => {
    const fetchData = async () => {
      const response = await getUpdateService();
      setData(response);
      setLoading(false);
    }
    fetchData();
  }, [setData]); 
  
  const createColumns = useCallback(() => {
    return [
      {
        name: "Cliente",        
        width: "8%", 
        cell: row => row.client             
      },
      {
        name: "Ubicación",
        width: "10%",        
        cell: row => row.location
      },
      {
        name: "#",
        width: "7%",
        cell: row => row.shortName
      },  
      {
        name: "Tipo de Cal",
        width: "10%", 
        cell: row => row.material
      },
      {
        name: "Presentación",
        width: "8%", 
        cell: row => row.presentation
      },
      {
        name: "Capacidad",     
        width: "8%",  
        cell: row => row.capacity
      },      
      {
        name: "Stock",      
        width: "6%",  
        cell: row => row.stock
      },
      {
        name: `Porcentaje de Stock (%)`,
        width: "150px",        
        cell: row => {
          return row.capacity ? <StatusBar total={row.capacity} partial={ row.stock }/> : "---"
        }
      },
      {
        name: "Unidades",
        width: "7%",
        cell: row => row.scheduledUnits
      },
      {
        name: "Observaciones",            
        cell: row => row.remarks
      },
      {
        name: "Registro", 
        width: "10%",        
        cell: row => {
          return <> { row.lastDay } <br/> {row.lastHour} </> ;
        },                
      },         
    ];
  }, []);

  const conditionalRowStyles = [
    {
      when: row => row.rowNumber % 2 === 1,
      style: {
        backgroundColor: "#f3f3f3",
      },
    },    
  ];
  
  return ( 
    <>      
      <div className="flex justify-between">
        <SectionTitle titleTop={"Status, Stock y programación de unidades"} titleBottom={"Estado de silos y operaciones"} />
        <DownloadReport data={ data } />
      </div>        
      {
        data && 
        <DataTable             
          columns={ createColumns() } 
          data={ data } 
          progressPending={ loading }
          customStyles={ customStyles }
          fixedHeader
          fixedHeaderScrollHeight="450px"
          conditionalRowStyles={conditionalRowStyles}
        />    
      }
    </>
  )
};