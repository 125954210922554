import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-responsive-modal";

// services
import { getAllCredentialService, updateCredentialRoleService } from "../services/auth/access.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Select } from "./Select";

// utils
import { MdOutlineAdminPanelSettings } from"react-icons/md";
import * as ERRORS from "../constants/errors";

// styles
import "react-responsive-modal/styles.css";

export const ModalUpdateRole = ({ credentialRoleId, callback, data }) => {
  const [ open, setIsOpen ] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [ params, setParams ] = useState({ loading: false, advice: null, color: null });

  const onOpenModal = () => setIsOpen(true);  
  
  const onCloseModal = () => {
    setParams({ loading: false, advice: null });
    reset();
    setIsOpen(false);
  }

  const onSubmit = async (formData) => {
    setParams({ ...params, loading: true })

    const body = JSON.parse(JSON.stringify(formData));
    const { status, message } = await updateCredentialRoleService(credentialRoleId, body);

    if( status ) {
      const credentials = await getAllCredentialService();
      callback(credentials);
    }
    
    setParams({ loading: false, advice: message });
  }

  return (
    <main>
      <button type="submit" onClick={ onOpenModal } className="modal__btn modal__btn--blue"> 
        <MdOutlineAdminPanelSettings />
      </button> 

      <Modal open={ open } onClose={ onCloseModal }>
        <div className="section__wrapper w-96">
          <h4 className="section__header section__header--font">
            Cambiar rol
          </h4>
          <form className="section__wrapper" onSubmit={ handleSubmit(onSubmit) }>
            <Select 
              id="roleId"
              label="Rol" placeholder="Seleccionar nuevo rol"
              options={ data }
              register={ register }
              constraints={{ 
                required: ERRORS.REQUIRED
              }} 
              errors={ errors.roleId }
            />
            { params.loading 
                ? <LoadingForm /> 
                : <button 
                    type="submit" 
                    className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
                  > 
                    Enviar 
                  </button>
            }
            { params.advice 
                && <span className="form__message">* { params.advice } *</span> 
            }
          </form>
        </div>
      </Modal>    
    </main>
  );
};