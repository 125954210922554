import React, { useContext } from "react";

import { FormRegisterUser } from "../../components/FormRegisterUser";
import { SectionTitle } from "../../components/SectionTitle";

import { NavbarContext } from "../../context/NavbarContext";

export const RegisterUser = () => {
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white md:flex">      
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}> 
      </div>
      
      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <SectionTitle titleTop={"Registrar nuevo usuario"} titleBottom={"Usuario "} />

        <main className="flex flex-col-reverse lg:flex-row gap-5 lg:justify-between">
          <div className="section__wrapper lg:w-[50%]">
            <h4 className="section__header section__header--font"> Datos del usuario </h4>
            <FormRegisterUser />
          </div>

          <div className="lg:w-2/5">
            <h4 className="section__header section__header--font"> Indicaciones </h4>
            <ul className="flex flex-col gap-3 py-4 font-medium font-inter text-gray-500 text-xs tracking-tight">
              <li className="before:absolute before:left-0 before:content-['▸'] relative pl-3 leading-5"> 
                Al crear un usuario, se asigna por default <span className="font-semibold text-accent"> operaciones123 </span> como contraseña de acceso.
              </li>     
              <li className="before:absolute before:left-0 before:content-['▸'] relative pl-3 leading-5"> 
                El rol <span className="font-semibold text-accent"> USER </span> tiene unico acceso para visualizar el estado de los silos.
              </li>     
              <li className="before:absolute before:left-0 before:content-['▸'] relative pl-3 leading-5"> 
                El rol <span className="font-semibold text-accent"> SUPERVISOR </span> tiene unico acceso para actualizar el estado de los silos.
              </li> 
              <li className="before:absolute before:left-0 before:content-['▸'] relative pl-3 leading-5"> 
                El rol <span className="font-semibold text-accent"> GESTOR </span> tiene acceso para actualizar y visualizar el estado de los silos.
              </li> 
              <li className="before:absolute before:left-0 before:content-['▸'] relative pl-3 leading-5"> 
                El rol <span className="font-semibold text-accent"> ADMIN </span> tiene acceso para editar cualquier acción dentro del portal.
              </li> 
            </ul> 
          </div> 
        </main>
      </div>
      
    </section>
  )
}