import React, { useContext } from "react";

import { SectionTitle } from "../../components/SectionTitle";
import { FormUpdatePassword } from "../../components/FormUpdatePassword";

import { NavbarContext } from "../../context/NavbarContext";

export const UpdatePassword = () => {
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white md:flex">
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}>
      </div>

      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <SectionTitle titleTop={"Cambiar contraseña"} titleBottom={"Usuario"} />
        <FormUpdatePassword />
      </div>
    </section>
  )
}