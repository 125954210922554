import React, { useEffect, useState } from "react";

// components
import { FormUpdateOwner } from "./FormUpdateOwner";
import { FormCreateOwner } from "./FormCreateOwner";

// services
import { getAllOwnerService } from "../services/cal/owner.service";

export const MenuOwner = () => {  
  const [ data, setData ] = useState(null);
  const [ tab, setTab ] = useState("new");
  
  useEffect( () => {
    const fechtData = async () => {
      const response = await getAllOwnerService();
      setData(response);
    }    
    fechtData();
  }, [ setData ] );

  const updateData = (data) => setData(data);
  
  return (  
    <main className="flex flex-col-reverse gap-3 lg:flex-row-reverse justify-between w-full">
      <div className="section__wrapper lg:w-[45%]">
        <h4 className="section__header section__header--font"> Clientes </h4>
        <ul className="font-inter text-sm text-gray-700 tracking-tight">
        { data && data.map( (item, index) => 
            <li key={ index } className="flex justify-between even:bg-gray-200/80 py-1.5 px-3">
              <span className="font-semibold"> { index + 1 } </span>
              <span className="font-mnedium"> { item.name } </span>
            </li>
        )}
        </ul>
      </div>
      <div className="section__wrapper lg:w-[45%]">
        <h4 className="section__header section__header--font flex gap-8"> 
          <span className="section__tab" onClick={ () => setTab("new") } > Nuevo </span> 
          <span className="section__tab" onClick={ () => setTab("update") } > Actualizar </span>
        </h4>
        { tab === "new" && <FormCreateOwner callback={ updateData }/> }
        { tab === "update" && <FormUpdateOwner callback={ updateData } data={ data } /> }
      </div>
    </main>
  )
}