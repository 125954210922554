import React from "react";

export const NavButton = ({ flag, handleFunction }) => {
  return (
    <div onClick={ handleFunction } className="w-6 h-5 space-y-1.5 cursor-pointer">
      <span className={`bg-gray-200 duration-500 h-0.5 block origin-top-left ${ flag ? "rotate-45 w-6":"w-4"}` }></span>
      <span className={`bg-gray-200 duration-500 h-0.5 block w-6 ${ flag && "opacity-0"}` }></span>
      <span className={`bg-gray-200 duration-500 h-0.5 block w-6 origin-bottom-left ${ flag && "-rotate-45"}` }></span>
    </div>
  )
}
