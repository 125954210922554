import React, { useContext } from "react";

import { TableUpdateStock } from "../../components/TableUpdateStock";

import { NavbarContext } from "../../context/NavbarContext";

import { getCurrentDay, getCurrentHour } from "../../services/common/date.service"

export const StatusCal = () => {
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white flex">
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}>
      </div>

      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <span className="text-gray-500 font-inter font-medium text-sm"> { getCurrentHour() } - { getCurrentDay() } </span>      
        <TableUpdateStock />
      </div>
    </section>
  )
}