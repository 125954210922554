import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-responsive-modal";

// services
import { updateUserService, getAllCredentialService } from "../services/auth/access.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";

// utils
import { AiOutlineEdit } from"react-icons/ai";
import * as ERRORS from "../constants/errors";

// styles
import "react-responsive-modal/styles.css";

export const ModalUpdateUser = ({ userId, name, surname, callback }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [ open, setIsOpen ] = useState(false);
  const [ params, setParams ] = useState({ loading: false, advice: null });

  const onOpenModal = () => setIsOpen(true);  
  
  const onCloseModal = () => {
    setParams({ loading: false, advice: null });
    reset();
    setIsOpen(false);
  }

  const onSubmit = async (formData) => {
    setParams({...params, loading: true})

    const body = JSON.parse(JSON.stringify(formData));
    
    const data = {
      name: body["name"] ? body["name"] : name,
      surname: body["surname"] ? body["surname"] : surname,
    }
    
    const { status, message } = await updateUserService(userId, data);

    if( status ) {
      const credentials = await getAllCredentialService();
      callback(credentials);
    }

    setParams({ loading: false, advice: message });
  };
  
  return (
    <main>
      <button type="submit" onClick={ onOpenModal } className="modal__btn modal__btn--green"> 
        <AiOutlineEdit />
      </button> 

      <Modal open={ open } onClose={ onCloseModal }>
        <div className="section__wrapper w-96"> 
          <h4 className="section__header section__header--font"> Actualizar datos </h4>
          <form className="section__wrapper" onSubmit={ handleSubmit(onSubmit) }>
            <Input 
              id="name" 
              type="text" 
              label="Nombre(s)" placeholder={`${name}`}
              register={ register }
              constraints={{ 
                minLength: { 
                  value: 3, 
                  message: ERRORS.MIN_3
                }, 
              }}
              errors={ errors.name }
            />

            <Input 
              id="surname" 
              type="text" 
              label="Apellido(s)" placeholder={`${surname}`}
              register={ register }
              constraints={{ 
                minLength: { 
                  value: 3, 
                  message: ERRORS.MIN_3
                }, 
              }}
              errors={ errors.surname } 
            />

            { params.loading 
                ? <LoadingForm /> 
                : <button 
                    type="submit"
                    className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
                  > 
                    Enviar 
                  </button> 
            }
            { params.advice 
                && <span className="form__message">* { params.advice } *</span> 
            }
          </form>       
        </div>
      </Modal>    
    </main>
  );
};