import React, { useEffect, useState, useCallback  } from "react";
import DataTable from "react-data-table-component";

// services
import { getAllCredentialService } from "../services/auth/access.service";
import { getAllRoleService } from "../services/auth/role.service";

// components
import { ModalResetPassword } from "./ModalResetPassword";
import { ModalRemoveUser } from "./ModalRemoveUser";
import { ModalUpdateUser } from "./ModalUpdateUser";
import { ModalUpdateRole } from "./ModalUpdateRole";

// utils
import { customStyles } from "../constants/tableProperties";

export const TableUpdateUser = () => {
  const [ users, setUsers ] = useState(null);
  const [ roles, setRoles ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  useEffect( () => {
    const fetch = () => {
      Promise.all([ 
        getAllCredentialService(), 
        getAllRoleService()
      ])
      .then( responses => {        
        setUsers(responses[0]);
        setRoles(responses[1]);
      })
      .catch( err => {
        console.log(err);
      });
      setLoading(false);
    }
    fetch();
  }, [ setRoles, setUsers ] );


  const updateData = (users) => setUsers(users);
  
  const createColumns = useCallback(() => {
    return [
      { 
        name: "#", 
        width: "7%", 
        cell: row => row.rowNumber 
      },
      { 
        name: "Nombres", cell: 
        row => row.name 
      },
      { 
        name: "Apellidos", 
        cell: row => row.surname 
      },
      { 
        name: "Username", 
        cell: row => row.username 
      },
      { 
        name: "Rol", 
        cell: row => row.role 
      },
      { 
        name: "Editar", 
        width: "7%",

        cell: row => <ModalUpdateUser userId={ row.userId } name={ row.name } surname={ row.surname } callback={ updateData } />
      },
      { 
        name: "Roles", 
        width: "7%",

        cell: row => <ModalUpdateRole credentialRoleId={ row.credentialRoleId } callback={ updateData } data={ roles }/> 
      },
      { 
        name: "Reset", 
        width: "7%",
        cell: row => <ModalResetPassword credentialId={ row.credentialId }/> 
      },
      { 
        name: "Remover", 
        width: "7%",
        cell: row => <ModalRemoveUser credentialId={ row.credentialId } callback={ updateData } /> 
      }
    ];
  }, [ roles ]);

  const conditionalRowStyles = [
    {
      when: row => row.rowNumber % 2 === 0,
      style: { 
        backgroundColor: "#f3f3f3",
      },
    }, 
  ];
  
  return (
    users && 
    <DataTable 
      columns={ createColumns() } 
      data={ users } 
      progressPending={ loading }
      customStyles={ customStyles }
      fixedHeader
      fixedHeaderScrollHeight="480px"
      conditionalRowStyles={conditionalRowStyles}
    />    
  )
};