export const ROOT_PAGE = '/'
export const HOME='/main'
export const FORBIDDEN = '/forbidden'
export const NOT_FOUND = '/not-found'

export const REGISTER_USER = '/access/user/register'
export const UPDATE_USER = '/access/user/update'
export const UPDATE_PASSWORD = '/user/password/update'

export const MATERIAL = '/cal/operation/material'
export const OWNER = '/cal/operation/client'
export const LOCATION = '/cal/operation/location'
export const OPERATION = '/cal/operation/operation'
export const SILO = '/cal/operation/silo'

export const UPDATE_STOCK_CAL = '/update-cal'
export const STATUS_STOCK_CAL = '/cal/operation/status'


export const routeAccess = {
  registerUser: {
    path: REGISTER_USER,
    roles: ['ADMIN']
  },

  updateUser: {
    path: UPDATE_USER,
    roles: ['ADMIN']
  },

  updatePassword: {
    path: UPDATE_PASSWORD,
    roles: ['ADMIN', 'GESTOR', 'USER', 'SUPERVISOR']
  },

  updateStockCal: {
    path: UPDATE_STOCK_CAL,
    roles: ['ADMIN', 'SUPERVISOR', 'GESTOR']
  },
  
  statusCal: {
    path: STATUS_STOCK_CAL,
    roles: ['ADMIN', 'GESTOR', 'USER']
  },

  material: {
    path: MATERIAL,
    roles: ['ADMIN']
  },

  owner: {
    path: OWNER,
    roles: ['ADMIN']
  },

  location: {
    path: LOCATION,
    roles: ['ADMIN']
  },

  operation: {
    path: OPERATION,
    roles: ['ADMIN']
  },
  silo: {
    path: SILO,
    roles: ['ADMIN']
  },

  forbidden: {
    path: FORBIDDEN,
    roles: ['ADMIN', 'SUPERVISOR','GESTOR', 'USER']
  },
  root: {
    path: ROOT_PAGE,
    roles: ['ADMIN', 'SUPERVISOR','GESTOR', 'USER']
  },
  main: {
    path: HOME,
    roles: ['ADMIN', 'SUPERVISOR','GESTOR', 'USER']
  }
};