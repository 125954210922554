export const getAllOwnerService = async () => {
  const URL = `/owner`;  
  const query = await fetch(URL)  
  const jsonData = await query.json();  
  return jsonData;
}

export const updateOwnerService = async (id, data) => {
  const URL = `/owner/update/${id}`;
  const requestSetup = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  const query = await fetch(URL, requestSetup);
  const jsonData = await query.json();
  return jsonData;
}

export const createOwnerService = async (data) => {
  const URL = `/owner/create`;
  const requestSetup = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  const query = await fetch(URL, requestSetup);
  const jsonData = await query.json();
  return jsonData;
}