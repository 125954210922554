import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// services
import { getAllOwnerService } from "../services/cal/owner.service";
import { getAllLocationService } from "../services/cal/location.service";
import { getAllOwnerLocationService, createOwnerLocationService } from "../services/cal/ownerLocation.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Select } from "./Select";

// utils 
import * as ERRORS from "../constants/errors";

export const FormCreateOwnerLocation = ({ callback }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  
  const [ owners, setOwners ] = useState(null);
  const [ locations, setLocations ] = useState(null);
  const [ params, setParams ] = useState({ loading: false, advice: null });

  useEffect( () => {
    const fetch = () => {
      Promise.all([ getAllLocationService(), getAllOwnerService() ])
      .then( responses => {        
        setLocations(responses[0]);
        setOwners(responses[1]);
      })
      .catch( err => {
        console.log(err);
      });
    }
    fetch();
  }, [setLocations, setOwners ] );

  const onSubmit = async (formData) => {
    setParams({ loading: true, advice: null });    

    const body = JSON.parse(JSON.stringify(formData));
    const { status, message } = await createOwnerLocationService(body);
    
    if ( status ) {
      const operations = await getAllOwnerLocationService();
      callback(operations);
      reset();
    }

    setParams({ loading: false, advice: message });    
  }

  return (   
    <form onSubmit={handleSubmit(onSubmit)} className="section__wrapper"> 
      <Select 
        id="ownerId"
        label="Cliente" placeholder="Seleccionar un cliente"
        register={ register }
        constraints= {{
          required: ERRORS.REQUIRED
        }}
        errors={ errors.ownerId }
        options={ owners } 
      />

      <Select 
        id="locationId"
        label="Ubicación" placeholder="Seleccionar una ubicación"
        register={ register }
        constraints= {{
          required: ERRORS.REQUIRED
        }}
        errors={ errors.locationId }
        options={ locations }
      />
    
      { params.loading 
        ? <LoadingForm /> 
        : <button 
            type="submit"
            className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
          > 
            Enviar 
          </button> 
      }
      { params.advice 
        && <span className="form__message">* { params.advice } *</span> 
      }
    </form>  
  );
};