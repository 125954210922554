import React, { useState } from "react";
import { useForm } from "react-hook-form";

// services
import { createMaterialService, getAllMaterialService } from "../services/cal/material.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";

// utils
import * as ERRORS from "../constants/errors";

export const FormCreateMaterial = ({ callback }) => {  
  const { register, handleSubmit, reset, formState:{ errors} } = useForm({ name: null });
  const [ params, setParams ] = useState( { loading: false, advice: null } );
     
  const onSubmit = async (formData) => {    
    setParams({ ...params, loading: true })
    
    const body = JSON.parse(JSON.stringify(formData));    
    const { status, message } = await createMaterialService(body);
    
    if ( status ) {
      const response = await getAllMaterialService();
      callback(response);
      reset();
    }

    setParams({ loading: false, advice: message });
  }

  return (            
    <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper">       
      <Input 
        id="name" 
        type="text" 
        label="Nombre" placeholder="Ingresar nombre a agregar" 
        register={ register }
        constraints={{ 
          minLength: { 
            value: 3, 
            message: ERRORS.MIN_3
          }, 
          pattern: {
            value: /^[A-Z ]*$/,
            message: ERRORS.ONLY_UPPERCASE
          },
          required: ERRORS.REQUIRED
        }}
        errors={ errors.name }
      />
      { params.loading 
        ? <LoadingForm /> 
        : <button 
            type="submit"
            className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
          > 
            Enviar 
          </button> 
      }
      { params.advice 
        && <span className="form__message">* { params.advice } *</span> 
      }
    </form>      
  )
}