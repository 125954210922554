import React, { useEffect, useState } from "react";

// services
import { getCountSiloService } from "../services/cal/operation.service";  
import { getAllPresentationService } from "../services/cal/presentation.service";

// components
import { FormCreateSilo } from "./FormCreateSilo";
import { FormUpdateSilo } from "./FormUpdateSilo";
import { FormRemoveSilo } from "./FormRemoveSilo";

export const MenuSilo = () => {
  const [ data, setData ] = useState(null);
  const [ presentations, setPresentations ] = useState(null)
  const [ tab, setTab ] = useState("new");
  
  useEffect( () => {    
    const fechtData = async () => {
      const data = await getCountSiloService();
      const dataPresentations = await getAllPresentationService();
      setData(data);
      setPresentations(dataPresentations);
    }
    fechtData();
  }, [ setData, setPresentations ]);

  const updateData = (data) => setData(data);

  return (        
    <main className="flex flex-col-reverse gap-3 lg:flex-row-reverse justify-between">
      <div className="section__wrapper lg:w-[45%]">
        <h4 className="section__header section__header--font"> Operaciones y Nro. de Silos </h4>
        <ul className="font-inter text-sm text-gray-700 tracking-tight">  
        { data && data.map( (item, index) => 
            <li key={ index } className="flex justify-between even:bg-gray-200/80 py-1.5 px-3">
              <span className="font-semibold"> { item.ownerName} / {item.locationName}</span>
              <span className="font-mnedium"> { item.materialName } - Count: { item.nroSilos} </span>
            </li>
        )}
        </ul>
      </div>
      <div className="section__wrapper lg:w-[45%]">
        <h4 className="section__header section__header--font flex gap-8"> 
          <span className="section__tab" onClick={ () => setTab("new") } > Nuevo silo</span> 
          <span className="section__tab" onClick={ () => setTab("update") } > Actualizar Silo </span> 
          <span className="section__tab" onClick={ () => setTab("remove") } > Remover Silo </span> 
        </h4>
        { tab === "new" && <FormCreateSilo callback={ updateData } presentations={ presentations }/> }
        { tab === "update" && <FormUpdateSilo presentations={ presentations } /> }
        { tab === "remove" && <FormRemoveSilo presentations={ presentations } /> }

      </div>
    </main>
  );
};