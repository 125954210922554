export const getCurrentDay = () => {
  const currentDate = new Date();
  const localDate = currentDate.toLocaleDateString();  
  return localDate.toString();
}

export const getCurrentHour = () => {
  const currentDate = new Date();
  const hours = currentDate.getHours().toString().length === 1 ? '0' + currentDate.getHours() : currentDate.getHours();
  const minutes = currentDate.getMinutes().toString().length === 1 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
  const seconds = currentDate.getSeconds().toString().length === 1 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
  
  return  hours + ':' + minutes + ':' + seconds; 
}