import React, { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";

import { SesionContext } from '../context/SesionContext';

import * as ROUTES from "../constants/routes";

export const PrivateRoute = ( {  isLoggedIn, roles } ) => {
  const { user } = useContext(SesionContext);
  const { role } = user;
  const access = roles.includes(role) ? true : false;  

  if (!access && isLoggedIn) return <Navigate to={ROUTES.FORBIDDEN} />;
  
  return (isLoggedIn && access) ? <Outlet /> : <Navigate to={ROUTES.ROOT_PAGE} />; 
}