import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// services
import { getAllOperationService } from "../services/cal/operation.service";
import { getAllOwnerLocationService } from "../services/cal/ownerLocation.service";
import { updateStockService } from "../services/cal/update.service";
import { getCurrentDay, getCurrentHour } from "../services/common/date.service";

import { saveInSpreadsheetService } from "../services/google-apis/spreadsheet.service";

import { updateSiloService } from "../services/cal/silo.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";

// utils
import * as ERRORS  from "../constants/errors";

export const FormUpdateStockCal = () => {    
  const { register, handleSubmit, reset, formState:{ errors } } = useForm();

  const [ operations, setOperations ] = useState(null);  
  const [ ownerLocation, setOwnerLocation ] = useState(null);
  
  const [ ownerLocationId, setOwnerLocationId ] = useState(null);
  const [ materialId, setMaterialId ] = useState(null);

  const [ params, setParams ] = useState({ advice: null, loading: false });
  
  const filterSilos = (arrayContainer) => {
    const filter = arrayContainer.filter( item => ( item.materialId === materialId ));
    return filter[0];
  }
  
  const filterOperations = (arrayContainer) => arrayContainer.filter(item => item.ownerLocationId === ownerLocationId );
  
  const operationByOwnerLocation = operations && ownerLocationId && filterOperations(operations);

  const silosByMaterial = operationByOwnerLocation && materialId && filterSilos(operationByOwnerLocation);

  const handleOwnerLocation = (e) => {
    setOwnerLocationId(e.target.value)
    reset();
  };  

  const handleMaterial = (e) => setMaterialId(e.target.value);

  const resetSelect = () => {
    const select_box = document.getElementById("owners");
    select_box.selectedIndex = 0;
  }
    
  useEffect( () => {
    const fetchOperations = () => {
      Promise.all([ getAllOperationService(), getAllOwnerLocationService() ])
      .then((responses)=> {        
        setOperations(responses[0]);
        setOwnerLocation(responses[1]);
      })
      .catch( err => {
        console.log(err);
      });      
    }    
    fetchOperations();
  }, [setOperations, setOwnerLocation]); 
  
 
  const onSubmit = async (formData) => {
    setParams({...params, loading: true})
  
    const body = JSON.parse(JSON.stringify(formData));  
    
    const currentSilo = silosByMaterial.silos.filter(item => item._id === body.siloId )    
    
    const siloId = body.siloId;
    const siloData = {      
      stock: body.stock,
      undScheduled: body.undScheduled ? body.undScheduled : "---",
      remarks: body.remarks ? body.remarks : "Sin observaciones",
      hour: getCurrentHour(),
      date: getCurrentDay(),
    }
    
    const updateData = {      
      operationId: silosByMaterial.mainId,
      hour: getCurrentHour(),
      date: getCurrentDay(),
    }

    const toSpreadsheet = {
      cliente: silosByMaterial.ownerName,
      destino: silosByMaterial.locationName,
      material: silosByMaterial.materialName,
      silo: currentSilo ? currentSilo[0].shortName : "",
      capacidad: currentSilo ? currentSilo[0].capacity : "",
      presentacion: currentSilo ? currentSilo[0].presentationId.name  : "",
      stock: body.stock,
      unidades: body.undScheduled ? body.undScheduled : "---",
      observaciones: body.remarks ? body.remarks : "Sin observaciones",
      hora: getCurrentHour(),
      fecha: getCurrentDay(),
    }    
            
    Promise.all([ updateSiloService(siloId, siloData), updateStockService(updateData), saveInSpreadsheetService(toSpreadsheet) ])
      .then((responses)=> {        
        reset();
        setOwnerLocationId(null);
        setMaterialId(null);
        resetSelect();
        setParams({ advice: "Se ha actualizado correctamente", loading: false });
      })
      .catch( err => {
        setParams({ advice: err, loading: false });
      });
  }

  return (
    <main className="section__wrapper lg:w-1/2">
      <div className="field__wrapper">
        <label htmlFor="owners" className="field__label field__label--font"> Operación: </label>
        <div className="field__input">
          <select id="owners" className="input__box input__box--font input__box--focus" onChange={ handleOwnerLocation } >
            <option value="" defaultValue> Seleccionar una opción </option>
            { ownerLocation && ownerLocation.map((element, key) => 
                <option key={ key } value={ element.id } >{ element.ownerName } - { element.locationName } </option>
            )}
          </select>
        </div>
      </div>

      <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper">
        <div className="field__wrapper">
          <label htmlFor="operationId"  className="field__label field__label--font"> CAL: </label>
          <div className="field__input">
            <select 
              id="operationId"
              className="input__box input__box--font input__box--focus"              
              { ...register("operationId", { onChange: handleMaterial, required: ERRORS.REQUIRED } ) } 
            >
              <option value="" defaultValue> Seleccione una opción </option>
              { operationByOwnerLocation && operationByOwnerLocation.map((element, key) => 
                  <option key={ key } value={ element.materialId }>
                    { element.materialName } 
                  </option>
              )}
            </select>
            { errors.operationId && <span className="field__error">{ errors.operationId.message }</span> }
          </div>
        </div>

        <div className="field__wrapper">
          <label htmlFor="siloId"  className="field__label field__label--font"> Silo: </label>
          <div className="field__input">
            <select 
              id="siloId"
              className="input__box input__box--font input__box--focus"
              { ...register("siloId", { required: ERRORS.REQUIRED } ) } 
            >
              <option value="" defaultValue> Seleccione una opción </option>
              { silosByMaterial && silosByMaterial.silos.map((element, key) =>                   
                  <option key={ key } value={ element._id }>
                    { element.shortName } - { element.presentationId.name } - Capacidad: { element.capacity } 
                  </option>
              )}
            </select>
            { errors.siloId && <span className="field__error">{ errors.siloId.message }</span> }
          </div>
        </div>
                            
        <Input 
          id="stock" 
          type="number" 
          label="Stock" placeholder="Ingresar el stock actual del silo"
          register={ register }
          errors={ errors.stock }
          constraints={{
            required: ERRORS.REQUIRED
          }}
        />

        <Input 
          id="undScheduled"
          type="number" 
          label="Unidades Programadas" placeholder="Ingresar la cantidad a actualizar (No obligatorio)"  
          register={ register }        
        />

        <Input 
          id="remarks" 
          type="text-area" 
          label="Obervaciones" placeholder="Observaciones encontradas (No obligatorio)"  
          register={ register }
        />
          
        { params.loading 
          ? <LoadingForm /> 
          : <button 
              type="submit"
              className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
            > 
              Enviar 
            </button> 
        }
        { params.advice && <span className="form__message">* { params.advice } *</span> }
      </form>    
    </main>
  )
}