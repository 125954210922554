import React, { useState } from "react";
import { useForm } from "react-hook-form";

// services
import { updateOwnerService, getAllOwnerService } from "../services/cal/owner.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";
import { Select } from "./Select";

// utils
import * as ERRORS from "../constants/errors";

export const FormUpdateOwner = ({ callback, data }) => {    
  const { register, handleSubmit, reset, formState: { errors } } = useForm({ name: null });
  const [ params, setParams ] = useState( { loading: false, advice: null } );
    
  const onSubmit = async (formData) => {    
    setParams({...params, loading: true})

    const { id, name } = JSON.parse(JSON.stringify(formData));
    const { status, message } = await updateOwnerService(id, { name });
        
    if ( status ) {
      const response = await getAllOwnerService();
      callback(response);
      reset();
    }    
        
    setParams({ loading: false, advice: message, });    
  }

  return (      
    <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper">       
      <Select 
        id="id" 
        type="text" 
        label="Antiguo nombre" placeholder="Seleccionar el nombre a cambiar" 
        options={ data }
        register={ register }
        constraints={{           
          required: ERRORS.REQUIRED
        }}
        errors={ errors.id }
      />  

      <Input 
        id="name" 
        type="text"
        label="Nuevo nombre" placeholder="Ingresar el nuevo nombre" 
        register={ register }
        constraints={{ 
          minLength: { 
            value: 3, 
            message: ERRORS.MIN_3
          }, 
          pattern: {            
            value: /^[A-Z ]*$/,
            message: ERRORS.ONLY_UPPERCASE
          },
          required: ERRORS.REQUIRED 
        }}
        errors={ errors.name }
      />          
      { params.loading 
        ? <LoadingForm /> 
        : <button 
            type="submit"
            className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
          > 
            Enviar 
          </button> 
      }
      { params.advice 
        && <span className="form__message">* { params.advice } *</span> 
      }
    </form>
  )
}