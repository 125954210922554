export const getAllOwnerLocationService = async () => {
  const URL = `/owner-location`;  
  const query = await fetch(URL)  
  const jsonData = await query.json();  
  return jsonData;
}

export const createOwnerLocationService = async (data) => {
  const URL = `/owner-location/create`;  
  const query = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })  
  const jsonData = await query.json();  
  return jsonData;
}

export const removeOwnerLocationService = async (id) => {
  const URL = `/owner-location/remove/${id}`;  
  const query = await fetch(URL, {
    method: 'PUT'
  })  
  const jsonData = await query.json();  
  return jsonData;
}