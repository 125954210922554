export const customStyles = {  
  headCells: {
    style: {
      paddingLeft: '10px', // override the cell padding for head cells
      paddingRight: '10px',
      backgroundColor: 'rgb(62,73,122)',
      color: 'rgb(255 , 255, 255)',
      fontFamily: 'inter',
    },
  },
  cells: {
    style: {        
      fontSize: '11px',
      minHeight: '50px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '10px',  
      paddingBottom: '10px',    
      color: 'rgb(82,82,82)',
      fontFamily: 'inter',
      fontWeight: '500',       
    },
  },
};
