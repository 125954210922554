import React from "react";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";

export const NavSideButton = ({ flag, handleFunction }) => {
  return (
    <div onClick={ handleFunction } className="bg-white cursor-pointer w-10 h-10 rounded-full flex justify-center items-center">
      <span className="text-accent text-lg">
        { flag ? <AiFillCaretRight /> : <AiFillCaretLeft /> }
      </span>
    </div>
  )
}
