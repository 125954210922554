import React, { useContext } from "react";

import { NavbarContext } from "../../context/NavbarContext"
import { SesionContext } from "../../context/SesionContext";

export const Home = () => {
  const { user } = useContext(SesionContext);
  const { sideMinimize } = useContext(NavbarContext);


  return (
    <section className="bg-white flex">
      <div className={`hidden md:flex ${ sideMinimize ? "md:w-20" : "md:w-60"} duration-700 `}></div>

      <div className={`${sideMinimize ? "md:w-full" :"md:w-[calc(100%-15rem)]" } px-5 py-20 md:px-10 md:py-5 duration-700`}>
        <div className="section__wrapper">
          <h3 className="font-inter font-bold text-5xl md:text-6xl text-accent tracking-tight"> 
            ¡Bienvenido! 
          </h3>
          <main className="font-inter text-sm text-gray-700 flex flex-col gap-3">
            <strong className="font-semibold">
              Estimado { user.name } {user.surname}:
            </strong>
            <p className="font-normal leading-6 md:w-[65%]"> 
              En este portal, podrás encontrar información basica sobre reportes de cal y otros servicios que te iremos proporcionando.
              Elija alguna opción del menú para acceder a la información que requiera.
            </p>      
          </main>
        </div>
      </div>
    </section>
  )
}