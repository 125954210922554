import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// services
import { getAllMaterialService } from "../services/cal/material.service";
import { getAllOwnerLocationService } from "../services/cal/ownerLocation.service";
import { createOperationService, getCountSiloService } from "../services/cal/operation.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";
import { Select } from "./Select";

// utils
import * as ERRORS  from "../constants/errors";

export const FormCreateOperation = ({ callback, token, updateToken, resetSilo, updateAdvice }) => {  
  const { register, handleSubmit, reset, formState:{ errors } } = useForm({ name: null });
  const [ data, setData ] = useState( null );
  const [ materials, setMaterials ] = useState( null );
  const [ params, setParams ] = useState( { loading: false, advice: null } );

  useEffect( () => {
    const fechtData = async () => { 
      Promise.all([ 
        getAllOwnerLocationService(), 
        getAllMaterialService() 
      ])
      .then( responses => {        
        setData(responses[0]);
        setMaterials(responses[1]);
      })
      .catch( err => {
        console.log(err);
      });
    }
    fechtData();
  }, [ setData, setMaterials ] );
     
  const onSubmit = async (formData) => {    
    setParams({ ...params, loading: true })
    
    const { ownerLocationId, materialId, siloId } = JSON.parse(JSON.stringify(formData)); 

    if ( siloId.toString().toUpperCase() === token.toString().toUpperCase() ) {
      const { status, message } = await createOperationService({ ownerLocationId, materialId, siloId });    
      if ( status ) {
        const response = await getCountSiloService();
        reset();
        resetSilo();
        callback(response);
        updateToken(null);
        updateAdvice(message);
      }
      return setParams({ loading: false, advice: message }); 
    }
    return setParams({ loading: false, advice: "Token no coindice" });     
  }

  return (
    <div className="section__wrapper">
      <p className="form__message "> { `Token generado: ${token}` } </p> 
      <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper">

        <div className="field__wrapper">
          <label htmlFor="ownerLocationId" className="field__label field__label--font"> Operación: </label>
          <div className="field__input">
            <select 
              {...register("ownerLocationId", { required: ERRORS.REQUIRED }) } 
              id="ownerLocationId"
              className="input__box input__box--font input__box--focus"
            >
              <option value="" defaultValue> Seleccione una Operación </option>
              { data && data.map((element, key) => 
                <option key={ key } value={ element.id } > { element.ownerName } - { element.locationName } </option> 
              )}
            </select>
            {
              errors.ownerLocationId && <p className="field__error">{ errors.ownerLocationId.message }</p>
            }
          </div>
        </div>

        <Select 
          id="materialId" 
          type="text" 
          label="Tipo de CAL" placeholder="Seleccionar tipo de CAL" 
          options={ materials }
          register={ register }
          errors={ errors.materialId }
          constraints={{ 
            required: ERRORS.REQUIRED 
          }}
        />

        <Input 
          id="siloId" 
          type="text" 
          label="Token" placeholder="Ingresar token generado" 
          register={ register }
          errors={ errors.siloId }
          constraints={{
            required: ERRORS.REQUIRED
          }}
        />

        { params.loading 
          ? <LoadingForm /> 
          : <button 
              type="submit"
              className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
            > 
              Enviar 
            </button> 
        }
        { params.advice 
          && <span className="form__message">* { params.advice } *</span> 
        }
      </form>  
    </div>          
  )
}