import React, { useContext } from "react";

import { MenuMaterial } from "../../components/MenuMaterial";
import { SectionTitle } from "../../components/SectionTitle";

import { NavbarContext } from "../../context/NavbarContext";

export const Material = () => {  
  const { sideMinimize } = useContext(NavbarContext);

  return (
    <section className="bg-white flex">
      <div className={`${ sideMinimize ? "fill__section--minimize" : "fill__section" } hidden md:flex duration-700`}>     
      </div>
      
      <div className={`${ sideMinimize ? "container__section--minimize" : "container__section" } container__section--padding duration-700`}>
        <SectionTitle titleTop={"Creación de modelos basicos"} titleBottom={"Material"} />               
        <MenuMaterial /> 
      </div>
    </section>
  )
}