import React from "react";

export const Input = ({ id, type, label, placeholder, register, constraints, errors }) => {
  return (  
    <div className="field__wrapper">
      <label htmlFor={ id } className="field__label field__label--font"> { label }: </label>
      <div className="field__input">
        <input  
          id={ id } 
          type={ type } 
          placeholder={ placeholder }
          className="input__box input__box--font input__box--focus"
          { ...register(id, constraints) }
        />
        { errors && <p className="field__error"> { errors.message } </p> }  
      </div>
    </div>
  )
} 