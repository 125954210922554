import React, { useEffect, useState, useContext } from "react";
import { Routes,  Route, BrowserRouter } from "react-router-dom";

// contexts
import { SesionContext } from "../context/SesionContext";

// services
import { checkAuthentication } from "../services/auth/access.service";

// pages
import { Login } from "../pages/Login";
import { Home } from "../pages/Home";
import { Forbidden } from "../pages/Forbidden";
import { NotFound } from "../pages/NotFound";

import { UpdateCal } from "../pages/UpdateCal";
import { StatusCal } from "../pages/statusCal";
import { RegisterUser } from "../pages/RegisterUser";
import { UpdatePassword } from "../pages/UpdatePassword";
import { User } from "../pages/User";

import { Operation } from "../pages/Operation";
import { Silo } from "../pages/Silo";
import { Material } from "../pages/Material";
import { Owner } from "../pages/Owner";
import { Location } from "../pages/Location";

// components
import { NavigationBar } from "../components/NavigationBar";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { LoadingForm } from "../components/LoadingForm";

// constants
import * as ROUTES from "../constants/routes";
import { routeAccess } from "../constants/routes";

export const AppRouter = () => {	 
  const [ loading, setLoading ] = useState(true);
  const { setUser, setLog, log } = useContext(SesionContext);
    
  useEffect( () => {
    const fecthData = async () => {      
      const response = await checkAuthentication();
      const isLoggedIn = response.isLoggedIn;
      if ( isLoggedIn ) {
        const { name, surname, role } = response;        
        setUser({ name, surname, role });
      }
      setLog(isLoggedIn);
      setLoading(false);
    }    
    fecthData();
  },[ setUser, setLog, setLoading])
  
  if( loading ) {
    return (
      <div className="flex justify-center w-screen h-screen items-center bg-gradient-to-b from-accent to-[#4e3274]"> 
        <LoadingForm />   
      </div>
    )
  }

	return (
    <BrowserRouter>           
      <div className=""> 
        { log && <NavigationBar /> }
        <Routes>
          <Route exact path={ routeAccess.registerUser.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.registerUser.roles}/> } >
            <Route  path=""  element={<RegisterUser />} />
          </Route>

          <Route exact path={ routeAccess.updateUser.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.updateUser.roles}/> } >
            <Route path="" element={<User />} />
          </Route>

          <Route exact path={ routeAccess.updatePassword.path } element={ <PrivateRoute isLoggedIn={log} roles={routeAccess.updatePassword.roles} /> } >
            <Route path=""  element={<UpdatePassword />} />
          </Route>

          <Route exact path={ routeAccess.material.path } element={ <PrivateRoute isLoggedIn={log} roles={routeAccess.material.roles} /> } >
            <Route path=""  element={<Material />} />
          </Route>

          <Route exact path={ routeAccess.owner.path } element={ <PrivateRoute isLoggedIn={log} roles={routeAccess.owner.roles} /> } >
            <Route path=""  element={<Owner />} />
          </Route>

          <Route exact path={ routeAccess.location.path } element={ <PrivateRoute isLoggedIn={log} roles={routeAccess.location.roles} /> } >
            <Route path=""  element={<Location />} />
          </Route>
            
          <Route exact path={ ROUTES.ROOT_PAGE } element={ <PublicRoute isLoggedIn={ log } /> } >
            <Route path="" element={ <Login /> } />
          </Route>

          <Route exact path={ routeAccess.updateStockCal.path } element={ <PrivateRoute isLoggedIn={log} roles={routeAccess.updateStockCal.roles} /> } >
            <Route path=""  element={<UpdateCal />} /> 
          </Route>

          <Route exact path={ routeAccess.statusCal.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.statusCal.roles}/> } >
            <Route path="" element={<StatusCal />} />       
          </Route>

          <Route exact path={ routeAccess.main.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.main.roles}/> } >
            <Route path="" element={<Home />} />
          </Route>
      
          <Route exact path={ routeAccess.operation.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.operation.roles}/> } >
            <Route path="" element={<Operation />} />
          </Route>

          <Route exact path={ routeAccess.silo.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.silo.roles}/> } >
            <Route path="" element={<Silo />} />       
          </Route>          

          <Route exact path={ routeAccess.forbidden.path } element={ <PrivateRoute isLoggedIn={ log } roles={routeAccess.forbidden.roles}/> } >
            <Route path="" element={<Forbidden />} /> 
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>        
      </div>
    </BrowserRouter>
	)
}