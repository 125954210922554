import React, { useState } from "react";
import { Modal } from "react-responsive-modal";

// Services
import { resetPasswordService } from "../services/auth/access.service";

// components
import { LoadingForm } from "./LoadingForm";

// utils
import { GrPowerReset } from"react-icons/gr";

// styles
import "react-responsive-modal/styles.css";

export const ModalResetPassword = ({ credentialId }) => {
  const [ open, setIsOpen ] = useState(false);
  const [ params, setParams ] = useState({ loading: false, advice: null });

  const onOpenModal = () => setIsOpen(true);  
  
  const onCloseModal = () => {
    setParams({ loading: false, advice: null });
    setIsOpen(false);
  }
  
  const handleButton = async () => {
    setParams({ ...params, loading: true });

    const { message } = await resetPasswordService(credentialId);
    
    setParams({ loading: false, advice: message })
  };
  
  return (
    <main>
      <button type="submit" onClick={ onOpenModal } className="modal__btn modal__btn--yellow"> 
        <GrPowerReset />
      </button> 

      <Modal open={ open } onClose={ onCloseModal }>
        <div className="section__wrapper w-80 font-inter items-center">
          <strong className="section__header--font"> 
            ¿Reiniciar la contraseña? 
          </strong>

          <div className="flex gap-5 text-gray-100">
            { params.loading
                ? <LoadingForm />
                : <button className="py-2 w-16 bg-red-600 rounded-lg active:scale-105" onClick={ handleButton }> 
                    SI 
                  </button>
            }
            <button className="py-2 w-16 bg-green-700 rounded-lg active:scale-105" onClick={ onCloseModal }> 
              NO 
            </button>
          </div>
          
          { params.advice 
              && <span className="form__message">* { params.advice } *</span> 
          }
        </div>
      </Modal> 
    </main>
  )
};