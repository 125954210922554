import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { RiOilLine } from "react-icons/ri"
// Context
import { SesionContext } from "../context/SesionContext";
import { NavbarContext } from "../context/NavbarContext";

// services
import { clearCookies } from "../services/auth/access.service"

// Components 
import { NavButton } from "./NavButton";
import { NavUsername } from "./NavUsername";
import { NavLink } from "./NavLink";
import { NavSideButton } from "./NavSideButton";

// Data
import { items, calItems } from "../constants/navItems";

export const NavigationBar = () => {
  const { user, resetValues } = useContext(SesionContext);
  const { sideMinimize, setSideMinimize } = useContext(NavbarContext);


  const [ menuStatus, updateMenu ] = useState(false);
  const { pathname } = useLocation();
  
  const navigate = useNavigate();

  const handleMenu = () => {        
    const menuItems = document.querySelector("#menu"); 
    menuItems.classList.toggle("display");
    updateMenu(!menuStatus);
  }

  const handleHiddenMenu = () => setSideMinimize(!sideMinimize);
  
  const handleScroll = () => {
    const body = document.querySelector("body");
    body.style.overflow = menuStatus ? "hidden md:auto" : "auto";
  }
    
  const handleLogOut = async () => {    
    const { status } = await clearCookies();

    if ( status ) {
      resetValues();
      navigate("/");
    }
  }
  
  handleScroll();

  return (    
    <nav className="relative z-10 bg-accent">
      <header className={`
        bg-inherit 
        fixed top-0
        h-14 w-full 
        z-10 duration-700  
        ${ sideMinimize ? "md:w-12": "md:w-60 border-b border-b-gray-400/50" } `}
      >
        <div className="absolute left-4 bottom-0 top-0 my-auto h-fit w-fit md:hidden">
          <NavButton handleFunction={ handleMenu } flag={ menuStatus } />
        </div>
        <div className="hidden md:flex absolute -right-5 bottom-0 top-0 my-auto h-fit w-fit bg-accent h- p-1 rounded-full justify-center items-center self-center place-content-center">
          <NavSideButton handleFunction={ handleHiddenMenu } flag={ sideMinimize } />
        </div>
        <div className={`absolute bottom-0 top-0 my-auto right-4 md:right-auto md:left-4 h-fit duration-700 ${ sideMinimize ? "md:hidden": "md:block" } `}>
          <NavUsername name={ user.name } surname={ user.surname } role={ user.role } />
        </div>        
      </header>

      <div 
        id="menu" 
        className={`
          bg-inherit
          fixed h-full w-full 
          pt-14 
          -left-full md:-left-60 
          md:translate-x-full duration-700 overflow-y-auto 
          ${ sideMinimize ? "md:w-36": "md:w-60" } `}
      >

        <div className={`${ sideMinimize ? "hidden": "flex" } flex-col justify-between h-full bg-inherit`} >
          <div className="bg-inherit">
            <div className="flex flex-col gap-2 px-4 py-2">
              <h2 className="nav__subheader"> Usuario </h2>
              <ul className="flex flex-col">
                { items.map( ({ path, name, icon }, key) =>
                  <li key={ key } onClick={ handleMenu } className="">
                    <NavLink currentPath={ pathname } itemPath={ path } text={ name } icon={ icon } />
                  </li>
                )} 
              </ul> 
            </div>
            <div className="flex flex-col gap-2 px-4 py-2">
              <h2 className="nav__subheader"> Consumo de CAL </h2>
              <ul className="flex flex-col">
                { calItems.map( ({ path, name, icon }, key) =>
                  <li key={ key } onClick={ handleMenu } className="">
                    <NavLink currentPath={ pathname } itemPath={ path } text={ name } icon={icon} />
                  </li>
                )} 
              </ul> 
            </div>  
            <div className="flex flex-col gap-2 px-4 py-2">
              <h2 className="nav__subheader"> Abastecimiento </h2>
              <ul className="flex flex-col">                
                <li onClick={ handleMenu }>
                  <a
                    className="nav__link nav__link--font nav__link--hover flex items-center"
                    href="https://forms.office.com/r/SLADxGkfCa"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiOilLine /> Combustible
                  </a>                  
                </li>                
              </ul> 
            </div> 
          </div>
          <div className="px-4 py-1 border-t border-t-gray-400/50 bg-inherit">
            <div className="nav__link nav__link--font nav__link--hover" onClick={handleLogOut}>
              <MdLogout className="my-auto text-base" /> Cerrar sesión
            </div>
          </div>
        </div>                        
      </div>
    </nav>
  )
};