export const saveInSpreadsheetService = async (data) => {
  const URL = `/spreadsheet/save`;
  const requestSetup = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  const query = await fetch(URL, requestSetup);
  const jsonData = await query.json();
  return jsonData;
}
