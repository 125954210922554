import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// services
import { getAllOperationService } from "../services/cal/operation.service";
import { getAllOwnerLocationService } from "../services/cal/ownerLocation.service";
import { updateSiloService } from "../services/cal/silo.service";


// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";
import { Select } from "./Select";

// utils
import * as ERRORS  from "../constants/errors";

export const FormUpdateSilo = ({ presentations }) => {    
  const { register, handleSubmit, reset, formState:{ errors } } = useForm();

  const [ operations, setOperations ] = useState(null);  
  const [ ownerLocation, setOwnerLocation ] = useState(null);
  
  const [ ownerLocationId, setOwnerLocationId ] = useState(null);
  const [ materialId, setMaterialId ] = useState(null);

  const [ params, setParams ] = useState({ advice: null, loading: false });
  
  const filterSilos = (arrayContainer) => {
    const filter = arrayContainer.filter( item => ( item.materialId === materialId ));
    return filter[0];
  }
  
  const filterOperations = (arrayContainer) => arrayContainer.filter(item => item.ownerLocationId === ownerLocationId );
  
  const operationByOwnerLocation = operations && ownerLocationId && filterOperations(operations);

  const silosByMaterial = operationByOwnerLocation && materialId && filterSilos(operationByOwnerLocation);

  const handleOwnerLocation = (e) => setOwnerLocationId(e.target.value);  

  const handleMaterial = (e) => setMaterialId(e.target.value);

  const resetSelect = () => {
    const select_box = document.getElementById("owners");
    select_box.selectedIndex = 0;
  }
    
  useEffect( () => {
    const fetchoperations = () => {
      Promise.all([ getAllOperationService(), getAllOwnerLocationService() ])
      .then((responses)=> {        
        setOperations(responses[0]);
        setOwnerLocation(responses[1]);        
      })
      .catch( err => {
        console.log(err);
      });      
    }    
    fetchoperations();
  }, [setOperations, setOwnerLocation]); 
  
 
  const onSubmit = async (formData) => {
    setParams({...params, loading: true})
  
    const body = JSON.parse(JSON.stringify(formData));  

    const data = {
      shortName: body.shortName,
      capacity: body.capacity,
      presentationId: body.presentationId
    }
        
    const { status, message } = await updateSiloService(body.siloId, data);

    if( status ) {
      reset();
      setOwnerLocationId(null);
      setMaterialId(null);
      resetSelect();
    }
    
    setParams({ advice: message, loading: false });
  }

  return (
    <main className="section__wrapper ">
      <div className="field__wrapper">
        <label htmlFor="owners" className="field__label field__label--font"> Operación: </label>
        <div className="field__input">
          <select id="owners" className="input__box input__box--font input__box--focus" onChange={ handleOwnerLocation } >
            <option value="" defaultValue> Seleccionar una opción </option>
            { ownerLocation && ownerLocation.map((element, key) => 
                <option key={ key } value={ element.id } >{ element.ownerName } - { element.locationName } </option>
            )}
          </select>
        </div>
      </div>

      <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper">
        <div className="field__wrapper">
          <label htmlFor="operationId"  className="field__label field__label--font"> CAL: </label>
          <div className="field__input">
            <select 
              id="operationId"
              className="input__box input__box--font input__box--focus"              
              { ...register("operationId", { onChange: handleMaterial, required: ERRORS.REQUIRED } ) } 
            >
              <option value="" defaultValue> Seleccionar una opción </option>
              { operationByOwnerLocation && operationByOwnerLocation.map((element, key) => 
                  <option key={ key } value={ element.materialId }>
                    { element.materialName } 
                  </option>
              )}
            </select>
            { errors.operationId && <span className="field__error">{ errors.operationId.message }</span> }
          </div>
        </div>

        <div className="field__wrapper">
          <label htmlFor="siloId"  className="field__label field__label--font"> Silo: </label>
          <div className="field__input">
            <select 
              id="siloId"
              className="input__box input__box--font input__box--focus"
              { ...register("siloId", { required: ERRORS.REQUIRED } ) } 
            >
              <option value="" defaultValue> Seleccionar una opción </option>
              { silosByMaterial && silosByMaterial.silos.map((element, key) =>                   
                  <option key={ key } value={ element._id }>
                    { element.shortName } - Capacidad: { element.capacity } 
                  </option>
              )}
            </select>
            { errors.siloId && <span className="field__error">{ errors.siloId.message }</span> }
          </div>
        </div>

        <h2 className="font-inter text-gray-700 font-medium"> Datos a actualizar </h2>
                            
        <Select 
          id="presentationId" 
          label="Presentación" placeholder="Seleccione una opción"  
          register={ register }
          options={ presentations }
          errors={ errors.presentationId }
          constraints={{
            required: ERRORS.REQUIRED
          }}
        />

        <Input 
          id="shortName" 
          type="text" 
          label="Short Id" placeholder="Ingresar el nuevo shortId del silo"
          register={ register }
          errors={ errors.shortName }
          constraints={{
            required: ERRORS.REQUIRED,
            pattern: {
              value: /^[a-z0-9 ]*$/,
              message: "No se admite mayusculas"
            },
          }}
        />

        <Input 
          id="capacity"
          type="number" 
          label="Capacidad" placeholder="Ingresar la cantidad a actualizar"  
          register={ register }     
          errors={ errors.capacity }
          constraints={{
            required: ERRORS.REQUIRED,
            pattern: {
              value: /^[0-9]*$/,
              message: "Solo números"
            },
          }}   
        />

          
        { params.loading 
          ? <LoadingForm /> 
          : <button 
              type="submit"
              className="form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover"
            > 
              Enviar 
            </button> 
        }
        { params.advice && <span className="form__message">* { params.advice } *</span> }
      </form>    
    </main>
  )
}