import { useState } from "react";

import { NavbarContext } from "./NavbarContext";

export const NavbarProvider = ({ children }) => {
  const [ sideMinimize, setSideMinimize ] = useState(false);
  
  const data = { 
    sideMinimize,
    setSideMinimize    
  }
    
  return (
    <NavbarContext.Provider value={ data }>
      { children }    
    </NavbarContext.Provider>
  )
}