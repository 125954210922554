import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { getCurrentDay, getCurrentHour } from "../services/common/date.service";

export const DownloadReport = ({ data }) => {
  
  const downloadReport = () => {
    const doc = new jsPDF('l', 'pt')

    doc.setFontSize(20);
    doc.setTextColor(54, 69, 79);
    doc.text(40, 30, 'Reporte del Status, Stock y programación de unidades');	
    doc.setFontSize(14);
    doc.text(40, 50, getCurrentDay() + " - " + getCurrentHour() );	
    
    let info = [];
    const red = [255,99,71];
    const yellow = [255, 255, 0];
    const green = [50,205,50];

    data.forEach( (element, index) => {
      const percentage = Number(((element.stock/element.capacity) * 100).toFixed())
      
      info.push([
        element.client,
        element.location ,
        element.shortName,
        element.material, 
        element.presentation,
        element.capacity, 
        { content: element.stock },        
        { 
          content: element.capacity === 0 ? "0%" : percentage + "%", 
          styles: { 
            halign: 'center', 
            fillColor: percentage > 33 ? ( percentage>66 ? green : yellow ) : red,
            fontStyle: 'bold',
            fontSize: 12
          }
        }, 
        { 
          content: element.scheduledUnits, 
          
        }, 
        { 
          content: element.remarks , 
          
        }, 
        { 
          content: element.lastDay + `\n` + element.lastHour, 
          
        },
      ])
    })
    
    autoTable(doc, { html: '#my-table' })

    autoTable(doc, {
      theme: 'striped',
      columnStyles: { 'scheduledUnits': { halign: 'center' } },
      headStyles:{
        valign: 'middle',
        halign : 'center'
      },
      bodyStyles: {
        valign: 'middle',
        halign : 'center',
        minCellHeight: 35,
      },
      head: [['Cliente', 'Ubicación', 'Silo', 'Tipo de CAL', 'Presentación', 'Capacidad', 'Stock', `Porcentage\n de Stock`, 'Unidades', 'Observaciones', 'Registro']],
      body: info,                  
    })

    const nameFile = "Reporte-" + getCurrentDay() + "-" + getCurrentHour() + ".pdf"
    doc.save(nameFile);    
  }
 
  return (
    <button 
      onClick={ downloadReport } 
      className="border h-fit w-fit px-5 py-2 bg-accent text-gray-100 font-inter uppercase tracking-tight text-xs hover:scale-105 active:scale-110">
      Generar reporte
    </button>
  )
}