export const updateStockService = async (data) => {
  const URL = `/stock/create`;
  const requestSetup = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };
  const query = await fetch(URL, requestSetup);
  const jsonData = await query.json();
  return jsonData;
}

export const getUpdateService = async () => {
  const URL = `/stock`;  
  const query = await fetch(URL);
  const jsonData = await query.json();  
  return jsonData;
}