import React, { useState,  } from "react";
import { useForm } from "react-hook-form";

// services
import { createSiloService } from "../services/cal/silo.service";

// components
import { LoadingForm } from "./LoadingForm";
import { Input } from "./Input";
import { Select } from "./Select";
import { FormCreateOperation } from "./FormCreateOperation"

// utils
import * as ERRORS from "../constants/errors";

export const FormCreateSilo = ({ callback, presentations }) => {  
  const { register, handleSubmit, reset, formState:{ errors } } = useForm({ name: null });  
  const [ token, setToken ] = useState( null );  
  const [ params, setParams ] = useState( { loading: false, advice: null } );

  const updateToken = (value) => setToken(value);

  const updateAdvice = (value) => setParams({...params, advice: value });
     
  const onSubmit = async (formData) => {    
    setParams({ ...params, loading: true })
    
    const body = JSON.parse(JSON.stringify(formData));        
    const { success, message, token } = await createSiloService( body ); 
    
    if( success ) {
      setToken( token );
      return setParams({ loading: false, advice: null });
    }
    return setParams({ loading: false, advice: message });
  }

  return (
    <main className="section__wrapper">
      <form onSubmit={ handleSubmit(onSubmit) } className="section__wrapper"> 
        <Input 
          id="shortName" 
          type="text"
          label="Short ID" placeholder="Ingresar nombre a agregar" 
          register={ register }      
          errors={ errors.shortName }
          constraints={{ 
            required: ERRORS.REQUIRED,
            pattern: {
              value: /^[a-z0-9 ]*$/,
              message: "No se admite mayusculas"
            },
          }}    
        />

        <Select 
          id="presentationId" 
          label="Presentación" placeholder="Seleccione una opción"  
          register={ register }
          options={ presentations }
          errors={ errors.presentationId }
          constraints={{
            required: ERRORS.REQUIRED
          }}
        />

        <Input 
          id="capacity" 
          type="number" 
          label="Capacidad" placeholder="Ingresar nombre a agregar" 
          register={ register }  
          errors={ errors.capacity }
          constraints={{ 
            required: ERRORS.REQUIRED,
            pattern: {
              value: /^[0-9]*$/,
              message: "Solo números"
            },
          }}           
        />

        { params.loading 
          ? <LoadingForm /> 
          : <button 
              type="submit" 
              className={`
                ${token ? 'hidden': 'block'} 
                form__btn form__btn--font form__btn--focus form__btn--active form__btn--hover`
              }
            > 
              Generar token 
            </button> 
        }
        { params.advice 
            && <span className="form__message">* { params.advice } *</span> 
        }
        
      </form>
      { token 
          && <FormCreateOperation 
                token={ token } 
                callback={ callback } 
                resetSilo={ reset } 
                updateToken={ updateToken } 
                updateAdvice={ updateAdvice } 
              /> 
      }
    </main>
  )
}